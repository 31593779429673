export const currencyMappings = [
  { code: 'GBP', symbol: '拢' },
  { code: 'EUR', symbol: '鈧�' },
  { code: 'USD', symbol: '$' },
  { code: 'TRY', symbol: '鈧�' },
  { code: 'AUD', symbol: 'A$' },
  { code: 'BDT', symbol: '唰�' },
  { code: 'BBD', symbol: 'Bds$' },
  { code: 'BRL', symbol: 'R$' },
  { code: 'BGN', symbol: '袥胁' },
  { code: 'CAD', symbol: 'CAD' },
  { code: 'CNY', symbol: '楼' },
  { code: 'CRC', symbol: '鈧�' },
  { code: 'CZK', symbol: 'K膷' },
  { code: 'DKK', symbol: 'Kr.' },
  { code: 'XCD', symbol: '$' },
  { code: 'EGP', symbol: 'E拢' },
  { code: 'HKD', symbol: 'hk$' },
  { code: 'ISK', symbol: 'ikr' },
  { code: 'JPY', symbol: '楼' },
  { code: 'MYR', symbol: 'RM' },
  { code: 'MUR', symbol: 'rs' },
  { code: 'MXN', symbol: 'mex$' },
  { code: 'NZD', symbol: 'nz$' },
  { code: 'NOK', symbol: 'kr' },
  { code: 'PLN', symbol: 'z艂' },
  { code: 'ZAR', symbol: 'R' },
  { code: 'SEK', symbol: 'kr' },
  { code: 'CHF', symbol: 'Fr.' },
  { code: 'TWD', symbol: 'nt$' },
  { code: 'THB', symbol: '喔�' },
  { code: 'AED', symbol: '丿.廿' },
]

export const gbpCurrency = [{ value: 'GBP', text: 'GBP' }]

export const currencyPairOptions = [
  {
    text: 'Euros (EUR)',
    value: 'EUR',
  },
  {
    text: 'Dollars (USD)',
    value: 'USD',
  },
  {
    text: 'Turkish Lira (TRY)',
    value: 'TRY',
  },
  {
    text: 'Australian Dollar (AUD)',
    value: 'AUD',
  },
  {
    text: 'Bangladeshi Taka (BDT)',
    value: 'BDT',
  },
  {
    text: 'Barbadian Dollars (BBD)',
    value: 'BBD',
  },
  {
    text: 'Brazillian Real (BRL)',
    value: 'BRL',
  },
  {
    text: 'Bulgarian Lev (BGN)',
    value: 'BGN',
  },
  {
    text: 'Canadian Dollars (CAD)',
    value: 'CAD',
  },
  {
    text: 'Chinese Yuan (CNY)',
    value: 'CNY',
  },
  {
    text: 'Costa Rican Colon (CRC)',
    value: 'CRC',
  },
  {
    text: 'Czech Koruna (CZK)',
    value: 'CZK',
  },
  {
    text: 'Danish Krone (DKK)',
    value: 'DKK',
  },
  {
    text: 'Eastern Caribbean dollar (XCD)',
    value: 'XCD',
  },
  {
    text: 'Egyptian Pound (EGP)',
    value: 'EGP',
  },
  {
    text: 'Hong Kong Dollar (HKD)',
    value: 'HKD',
  },
  {
    text: 'Icelandic Krona (ISK)',
    value: 'ISK',
  },
  {
    text: 'Japanese Yen (JPY)',
    value: 'JPY',
  },
  {
    text: 'Malaysian Ringgit (MYR)',
    value: 'MYR',
  },
  {
    text: 'Mauritian Rupee (MUR)',
    value: 'MUR',
  },
  {
    text: 'Mexican Peso (MXN)',
    value: 'MXN',
  },
  {
    text: 'New Zealand Dollar (NZD)',
    value: 'NZD',
  },
  {
    text: 'Norwegian Krone (NOK)',
    value: 'NOK',
  },
  {
    text: 'Polish Z艂oty (PLN)',
    value: 'PLN',
  },
  {
    text: 'South African Rand (ZAR)',
    value: 'ZAR',
  },
  {
    text: 'Swedish Krona (SEK)',
    value: 'SEK',
  },
  {
    text: 'Swiss Franc (CHF)',
    value: 'CHF',
  },
  {
    text: 'Taiwan Dollar (TWD)',
    value: 'TWD',
  },
  {
    text: 'Thai Baht (THB)',
    value: 'THB',
  },
  {
    text: 'UAE Dirham (AED)',
    value: 'AED',
  },
]

export const currencyTypeMappings = {
  'money-travel-card': {
    text: "A convenient prepaid way for spending abroad that's safer and easier to track.",
    buttonText: 'Show travel money cards',
    href: '/travel-money/travel-prepaid-cards/results',
  },
  'travel-insurance': {
    text: 'Get cover to protect yourself from the cost of potential problems when travelling.',
    buttonText: 'Get a travel insurance quote',
    href: '/travel-insurance',
  },
  'prepaid-card': {
    text: 'A prepaid card thats useful for travelling but can be used at home too',
    buttonText: 'Show prepaid cards',
    href: '/travel-money/prepaid-cards/results',
  },
}

export const flagMappings = {
  GBP: 'UK',
  EUR: 'EU',
  USD: 'US',
  TRY: 'TR',
  AUD: 'AU',
  BDT: 'BD',
  BBD: 'BB',
  BRL: 'BR',
  BGN: 'BG',
  CAD: 'CA',
  CNY: 'CN',
  CRC: 'CR',
  CZK: 'ZK',
  DKK: 'DK',
  XCD: 'XCD',
  EGP: 'EG',
  HKD: 'HK',
  ISK: 'IS',
  JPY: 'JP',
  MYR: 'MY',
  MUR: 'MU',
  MXN: 'MX',
  NZD: 'NZ',
  NOK: 'NO',
  PLN: 'PL',
  ZAR: 'ZA',
  SEK: 'SE',
  CHF: 'CH',
  TWD: 'TW',
  THB: 'TH',
  AED: 'AE',
}
