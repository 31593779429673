const tabs = document.querySelectorAll('[data-spark-tabs]')

tabs.forEach((tab: any) => {
  if (tab.dataset.sparkHydrated === 'true') {
    return
  }
  const list = tab.childNodes[0]
  const panels = tab.childNodes[1]
  const buttons = list.childNodes[0].childNodes
  const buttonClasses = Array.from(buttons).map((b: any) => ({
    class: b.getAttribute('class'),
    disabled: b.getAttribute('disabled') === '',
  }))
  const activeClassName = buttonClasses.find((obj) => !obj.disabled)?.class
  const disabledClassName = buttonClasses.find((obj) => obj.disabled)?.class

  buttons.forEach((button: any, index: number) => {
    button.addEventListener('click', () => {
      const panel = panels.childNodes[index]
      button.setAttribute('disabled', '')

      const display = window.getComputedStyle(panel, null).display
      if (display === 'none') {
        panel.style.display = 'block'
        button.setAttribute(
          'class',
          button.getAttribute('class')?.replace(activeClassName, disabledClassName),
        )

        buttons.forEach((b: any, i: number) => {
          if (i !== index) {
            b.removeAttribute('disabled')
            b.setAttribute(
              'class',
              b.getAttribute('class')?.replace(disabledClassName, activeClassName),
            )
          }
        })
        panels.childNodes.forEach((p: any, i: number) => {
          if (i !== index) {
            p.style.display = 'none'
          }
        })
      }
    })
  })
})
