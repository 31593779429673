// @ts-nocheck
import { debounce } from '../utils/debounce'

class ProviderLogoPlatinum {
  constructor(providerLogoPlatinum: Element) {
    this.$providerLogoPlatinum = providerLogoPlatinum
    this.$providerLogoPlatinumPhotobanner = providerLogoPlatinum.querySelector(
      '.ProviderLogoPlatinum__photobanner',
    )
    this.$providerLogoImageRepeatList = providerLogoPlatinum.querySelectorAll(
      '.ProviderLogoPlatinum__image--desktop',
    )
    this.handleScroll = this.handleScroll.bind(this)

    this.handleScroll()

    window.addEventListener('resize', debounce(this.handleScroll, 100), false)
    window.addEventListener('orientationchange', debounce(this.handleScroll, 100), false)
  }

  handleScroll() {
    const isOverflown = this.isOverflown(
      this.$providerLogoPlatinum,
      this.$providerLogoPlatinumPhotobanner,
    )

    if (!isOverflown) {
      this.$providerLogoPlatinumPhotobanner.classList.add(
        'ProviderLogoPlatinum__photobanner--fixed',
      )
    } else {
      this.$providerLogoPlatinumPhotobanner.classList.remove(
        'ProviderLogoPlatinum__photobanner--fixed',
      )
    }

    Array.from(this.$providerLogoImageRepeatList).forEach((image) => {
      if (!isOverflown) {
        image.classList.add('ProviderLogoPlatinum__image--fixed')
      } else {
        image.classList.remove('ProviderLogoPlatinum__image--fixed')
      }
    })
  }

  isOverflown(providerLogoPlatinum, providerLogoPlatinumPhotobanner) {
    const { clientWidth: wrapperClientWidth } = providerLogoPlatinum
    const { scrollWidth: bannerScrollWidth } = providerLogoPlatinumPhotobanner
    return wrapperClientWidth < bannerScrollWidth
  }
}

document
  .querySelectorAll('.ProviderLogoPlatinum')
  ?.forEach((providerLogoPlatinumWrapper) => new ProviderLogoPlatinum(providerLogoPlatinumWrapper))
