const topPicks = document.querySelectorAll('[data-top-picks]')

topPicks.forEach((e) => {
  const showDetailsCTA = e.querySelector('[data-top-picks-details-cta]')

  const showDetailsCTATarget = e.querySelector(
    '[data-top-picks-show-details-cta-text]',
  ) as HTMLElement

  const addons = e.querySelectorAll('[data-top-picks-addons]')

  const chevronIconDown = e.querySelector('[data-top-picks-chevron-down]') as HTMLElement
  const chevronIconUp = e.querySelector('[data-top-picks-chevron-up]') as HTMLElement

  showDetailsCTA?.addEventListener('click', () => {
    if (showDetailsCTATarget?.innerText === 'Show Details') {
      Array.from(addons)?.map((t) => t.classList.remove('top-picks-card__details__addonsHide'))
      showDetailsCTATarget.textContent = 'Hide Details'
      chevronIconDown.style.display = 'none'
      chevronIconUp.style.display = 'block'
    } else {
      Array.from(addons)?.map((t) => t.classList.add('top-picks-card__details__addonsHide'))
      showDetailsCTATarget.textContent = 'Show Details'
      chevronIconDown.style.display = 'block'
      chevronIconUp.style.display = 'none'
    }
  })
})
