import { trackComponent } from './tracking'

const clickElements = document.querySelectorAll('[data-click-tracking]')
const accordionElements = document.querySelectorAll('[data-click-accordion-tracking]')
const selectElements = document.querySelectorAll('[data-select-tracking]')

const getComponentData = (trackingProps) => ({
  /* eslint-disable camelcase */
  component_category: trackingProps.component_category,
  component_id: trackingProps.component_id,
  component_name: trackingProps.component_name,
  contentful_name: trackingProps.contentful_name,
  contentful_variant: trackingProps.contentful_variant,
})

const trackClickEvent = (element: Element) => {
  const trackingProps = JSON.parse(element.getAttribute('data-click-tracking') ?? '{}')
  const componentData = getComponentData(trackingProps)
  const event = trackingProps.event ?? 'Dynamic Component Event'
  const eventName = trackingProps.event_name ?? 'CTAButtonClicked'

  trackComponent(event, eventName, componentData, {
    ...trackingProps.additionalProps,
  })
}

const trackClickAccordionEvent = (element: Element) => {
  const accordion = element.getAttribute('data-click-accordion-tracking')
  const trackingProps = accordion ? JSON.parse(accordion) : ''
  const componentData = getComponentData(trackingProps)

  const detailsOpen = element.parentElement?.hasAttribute('open')

  const event = detailsOpen ? 'Accordion Closed' : 'Accordion Opened'
  const eventName = trackingProps.event_name ?? event

  trackComponent(event, eventName, componentData, {
    ...trackingProps.additionalProps,
  })
}

const trackSelectEvent = (element: Element, selected: boolean) => {
  const trackingProps = JSON.parse(element.getAttribute('data-select-tracking') ?? '{}')
  const componentData = getComponentData(trackingProps)
  const event = trackingProps.event ?? 'Dynamic Component Event'

  let eventName

  if (selected) {
    const selectedElement = element as HTMLSelectElement
    eventName = trackingProps.selectEventName ?? 'Option Selected'
    const selectedOption = selectedElement.selectedOptions[0]
    trackingProps.additionalProps = {
      ...trackingProps.additionalProps,
      title: selectedOption.text,
      index: selectedOption.index,
    }
  } else {
    eventName = trackingProps.clickEventName ?? 'Select Clicked'
  }

  trackComponent(event, eventName, componentData, {
    ...trackingProps.additionalProps,
  })
}

clickElements.forEach((element) => {
  element?.addEventListener('click', () => {
    trackClickEvent(element)
  })

  element?.addEventListener('auxclick', () => {
    trackClickEvent(element)
  })
})

accordionElements.forEach((element) => {
  element?.addEventListener('click', () => {
    trackClickAccordionEvent(element)
  })

  element?.addEventListener('auxclick', () => {
    trackClickAccordionEvent(element)
  })
})

selectElements.forEach((element) => {
  element?.addEventListener('click', () => {
    trackSelectEvent(element, false)
  })

  element?.addEventListener('auxclick', () => {
    trackSelectEvent(element, false)
  })

  element?.addEventListener('change', () => {
    trackSelectEvent(element, true)
  })
})
