import { get } from './utils/use-fetch'

interface ExistingConsentParams {
  email?: string
  usci?: string
  consentName: string
  consentType?: string
}

interface AllExistingConsentParams {
  email?: string
  usci?: string
}

const requestUsciFromEmail = async (email: string): Promise<any> => {
  const path = `/api/email/${encodeURIComponent(email)}`

  const response = await get(path).then((data: any): string => data?.id || '')

  return response
}

const requestExistingConsentFromUsci = async (
  usci: string,
  params: ExistingConsentParams,
): Promise<boolean> => {
  const path = `/api/customer/${usci}/consent`

  const response = await get(path).then(
    (existingConsent: false | Array<Record<string, unknown>>) =>
      existingConsent &&
      existingConsent.some((consent: { name: string }) => consent.name === params.consentName),
  )

  return response
}

const requestAllConsentsFromUsci = async (usci: string): Promise<any> => {
  const path = `/api/customer/${usci}/consent`

  const response = await get(path).then((existingConsent: any) => {
    return existingConsent
  })

  return response
}

export const getExistingConsent = async (params: ExistingConsentParams): Promise<any> => {
  let usci: string = params.usci || ''
  if (params.email) {
    usci = await requestUsciFromEmail(params.email)
  }

  return usci && requestExistingConsentFromUsci(usci, params)
}

export const getAllConsents = async (params: AllExistingConsentParams): Promise<any> => {
  let usci: string = params.usci || ''
  if (params.email) {
    usci = await requestUsciFromEmail(params.email)
  }

  return !!usci && requestAllConsentsFromUsci(usci)
}
