import yieldToMain from '../utils/yield-to-main'

document.querySelectorAll('[data-tabs-selector]').forEach((selector) => {
  const radioButtons = selector.querySelectorAll('[data-tabs-selector-radio]')
  const tabsSelectorContent = selector.querySelectorAll('[data-tabs-selector-content]')

  function hideTabContent() {
    Array.from(tabsSelectorContent).forEach((tabsSelector) => {
      tabsSelector.style.display = 'none'
    })
  }

  Array.from(radioButtons).forEach((radioButton) => {
    radioButton.addEventListener('click', async (event) => {
      event.target.classList.add('radio-checked')
      await yieldToMain()
      Array.from(radioButtons).forEach((rb, i) => {
        const index = parseInt(radioButton.dataset.tabsSelectorRadio)
        if (i !== index) {
          rb.parentElement.classList.remove('radio-checked')
        }
      })
      hideTabContent()
      const index = radioButton.dataset.tabsSelectorRadio
      tabsSelectorContent[index].style.display = 'block'
    })
  })
})
