const init = () => {
  const carousels = [...document.querySelectorAll('.eevee-carousel-wrapper')]

  carousels.forEach((carousel) => {
    const dots = [...carousel.querySelectorAll('.eevee-carousel-dot')]
    const slides = [...carousel.querySelectorAll('.eevee-carousel-slide')]
    const observer = new IntersectionObserver(
      (entries) => {
        const activeDots = [...carousel.querySelectorAll('.eevee-carousel-active-dot')]
        const [inView] = entries.filter(({ isIntersecting }) => isIntersecting)
        const getElementInview = inView?.target?.getAttribute('data-eevee-carousel-index')
        const elIndex = parseInt(getElementInview, 10)

        if (!isNaN(elIndex)) {
          activeDots.forEach((ad) => ad?.classList?.remove('eevee-carousel-active-dot'))
          dots[elIndex]?.classList?.add('eevee-carousel-active-dot')
        }
      },
      { rootMargin: '0%', threshold: 1 },
    )

    slides.forEach((slide) => {
      // set an observer on each slide
      observer.observe(slide)
    })

    dots.forEach((dot, i) => {
      const currentImage = carousel.querySelector(`[data-eevee-carousel-index="${i}"]`)
      dot.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          currentImage.scrollIntoView({
            block: 'nearest',
            inline: 'center',
            behavior: 'smooth',
          })
        }
      })
      dot.addEventListener('click', () => {
        currentImage.scrollIntoView({
          block: 'nearest',
          inline: 'center',
          behavior: 'smooth',
        })
      })
    })
  })
}

export default init
