const pills = document.querySelectorAll('[data-pills]')

pills.forEach((pill: any) => {
  const list = pill.childNodes[0] // The pill buttons container
  const dropdown = pill.childNodes[1] // The dropdown container
  const panels = pill.childNodes[2] // The panels container
  const buttons = list.childNodes
  const select = dropdown.querySelector('select')

  const activatePill = (index: number) => {
    const panel = panels.childNodes[index]
    const button = buttons[index]

    button.setAttribute('disabled', '')
    button.classList.add('disabled')
    button.classList.add('active')

    panel.style.display = 'block'

    buttons.forEach((b: any, i: number) => {
      if (i !== index) {
        b.removeAttribute('disabled')
        b.classList.remove('disabled')
        b.classList.remove('active')

        const otherPanel = panels.childNodes[i]
        otherPanel.style.display = 'none'
      }
    })
  }

  buttons.forEach((button: any, index: number) => {
    button.addEventListener('click', () => {
      activatePill(index)
      select.value = button.innerText
    })
  })

  select.addEventListener('change', (event: any) => {
    const selectedValue = event.target.value

    const selectedIndex = Array.from(buttons).findIndex(
      (button: any) => button.innerText === selectedValue,
    )

    if (selectedIndex !== -1) {
      activatePill(selectedIndex)
    }
  })
})
