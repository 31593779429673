import getWindow from '../utils/get-window'
import { track } from '../utils/tracking'

interface Experience {
  flag: string
  cohort: string
  iteration?: string
}

const trackExperimentAssigned = ({ flag, cohort, iteration }: Experience) => {
  track('Experience Bucket', `${flag}:${cohort}`, undefined, undefined, false, 'experiments')

  getWindow()?.nerdalytics.track('Experiment Loaded', {
    experiment_id: flag,
    variation_id: `${cohort}`,
    iteration_id: iteration,
  })
}

function run() {
  const experiences = (getWindow() as any).__experiences__ ?? {}

  Object.keys(experiences).forEach((flagId: string) => {
    const experience = experiences[flagId]

    if (experience.recording) {
      trackExperimentAssigned({
        flag: flagId,
        cohort: experience.cohort,
        iteration: experience.iteration,
      })
    }
  })
}

run()
