const buttonActions: NodeListOf<HTMLElement> = document.querySelectorAll('[data-button-action]')

buttonActions.forEach((element: HTMLElement) => {
  const actiontype = element.dataset.buttonAction

  if (actiontype === 'change-bundle-cta') {
    element.addEventListener('click', () => {
      history?.back()
    })
  }
})
