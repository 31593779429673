const triggerElements = document.querySelectorAll('[data-trigger-element-id]')

triggerElements.forEach((e) => {
  const triggerElementId: any = e.getAttribute('data-trigger-element-id')
  const tooltipPlatinumShow: any = document.getElementById(triggerElementId)

  const toolTipMask = e.querySelector('[data-tooltip-mask]')
  const toolTipClose = e.querySelector('[data-tool-tip-close]')

  tooltipPlatinumShow.addEventListener('click', () => {
    toolTipMask?.classList.add('TooltipPlatinum__active')
  })

  toolTipMask?.addEventListener('click', () => {
    toolTipMask.classList.remove('TooltipPlatinum__active')
  })
  toolTipClose?.addEventListener('click', () => {
    toolTipMask?.classList.remove('TooltipPlatinum__active')
  })
})
