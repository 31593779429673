import { trackComponent } from './tracking'

const viewTrackingElements = document.querySelectorAll('[data-view-tracking]')
const options = { threshold: 0.5 }

const getComponentData = (trackingProps) => ({
  /* eslint-disable camelcase */
  component_category: trackingProps.component_category,
  component_id: trackingProps.component_id,
  component_name: trackingProps.component_name,
  contentful_name: trackingProps.contentful_name,
  contentful_variant: trackingProps.contentful_variant,
})

const intersectionObserver = new IntersectionObserver((entries, observer) => {
  entries
    .filter((entry) => entry.isIntersecting && entry.intersectionRatio >= options.threshold)
    .forEach((entry) => {
      const trackingProps = JSON.parse(entry.target.getAttribute('data-view-tracking') ?? '{}')
      const componentData = getComponentData(trackingProps)
      const event = trackingProps.event ?? 'Dynamic Component Event'
      const event_name = trackingProps.event_name ?? 'Element Viewed'

      trackComponent(event, event_name, componentData, { ...trackingProps.additionalProps })
      observer.unobserve(entry.target)
    })
}, options)

viewTrackingElements.forEach((element: any) => {
  intersectionObserver.observe(element)
})
