import { debounce } from '../utils/debounce'

const sections = document?.querySelector('#app')?.querySelectorAll('div[id],section[id]')
const stickyNavTitle = document.querySelector('#navigationTitle')
const stickyNavWrapper = document.querySelector('[data-sticky-nav-target="stickyNavWrapper"]')
const stickyNavHeader = document.querySelector('#stickyNavHeader')
const subContainerContent = document.querySelector('[data-sticky-nav-target="subContainerContent"]')
const leftIconButton = document.querySelector('[data-sticky-nav-target="leftIconButton"]')
const rightIconButton = document.querySelector('[data-sticky-nav-target="rightIconButton"]')
const brand = window.__BRAND__

function getOffsetTop(element) {
  const rect = element.getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return rect.top + scrollTop
}

function scrollTabs(event, distance) {
  const arrLeft = leftIconButton.getElementsByTagName('use')
  const arrRight = rightIconButton.getElementsByTagName('use')

  if (distance > 0) {
    leftIconButton.classList.remove('disabledIcon')
    rightIconButton.classList.add('disabledIcon')

    arrLeft[0].setAttribute('href', `/fs-core-assets/icons/${brand}/arrow-left.svg?v=12#arrow-left`)
    arrRight[0].setAttribute(
      'href',
      `/fs-core-assets/icons/${brand}/ButtonRightDisabled.svg?v=12#ButtonRightDisabled`,
    )
  } else {
    leftIconButton.classList.add('disabledIcon')
    rightIconButton.classList.remove('disabledIcon')

    arrLeft[0].setAttribute(
      'href',
      `/fs-core-assets/icons/${brand}/ButtonLeftDisabled.svg?v=12#ButtonLeftDisabled`,
    )
    arrRight[0].setAttribute(
      'href',
      `/fs-core-assets/icons/${brand}/arrow-right.svg?v=12#arrow-right`,
    )
  }

  subContainerContent.scrollBy({
    left: subContainerContent.offsetWidth * distance,
    behavior: 'smooth',
  })
  event.preventDefault()
}

function handleScroll() {
  const scrollY = window.pageYOffset
  if (document.getElementsByClassName('sticky')[0]) {
    document.getElementsByClassName('active')[0]?.scrollIntoView()
  }

  sections.forEach((current) => {
    const sectionHeight = current.offsetHeight + 96
    const sectionTop = getOffsetTop(current) - 200
    const sectionId = current?.getAttribute('id')

    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
      document
        .querySelector('.navigation a[data-ele*=data-' + sectionId + ']')
        ?.classList.add('active')
    } else {
      document
        .querySelector('.navigation a[data-ele*=data-' + sectionId + ']')
        ?.classList.remove('active')
    }
  })
}

function navHighlighter() {
  const scrollY = stickyNavWrapper.getBoundingClientRect().y

  if (scrollY < 0) {
    stickyNavHeader.classList.add('sticky')
    stickyNavTitle.classList.add('hide-title')

    leftIconButton.style.visibility = 'hidden'
    rightIconButton.style.visibility = 'hidden'
  } else {
    stickyNavHeader.classList.remove('sticky')
    stickyNavTitle.classList.remove('hide-title')

    leftIconButton.style.visibility = 'visible'
    rightIconButton.style.visibility = 'visible'
  }

  debounce(handleScroll, 750)()
}

sections.forEach((ele) => ele.classList.add('scroll-margin'))

if (stickyNavHeader) {
  window.addEventListener('scroll', navHighlighter)
} else {
  window.removeEventListener('scroll', navHighlighter)
}

if (leftIconButton) {
  leftIconButton.addEventListener('click', function (event) {
    scrollTabs(event, -1)
  })
}

if (rightIconButton) {
  rightIconButton.addEventListener('click', function (event) {
    scrollTabs(event, 1)
  })
}
