import { trackComponent } from 'critical/tracking'

interface Action {
  text: string
  description: string
  links: Array<{ text: string; href: string }>
}
interface Answer {
  text: string
  nextQuestion?: Question
  action: Action
}

interface Question {
  id: number
  question: string
  answers: Array<Answer>
  description: string
}

const triages = document.querySelectorAll("[data-triage='triage']")

const addQuestion = (
  nextq: Question,
  componentId: string,
  contentfulName: string,
  mainElement: HTMLElement,
) => {
  const section = document.createElement('section')
  section.classList.add('Triage__qa')
  section.style.opacity = '0'
  section.dataset.triage = 'question'
  section.dataset.triageIdValue = String(nextq.id)

  const title = document.createElement('h3')
  title.classList.add('Triage__qa__question')
  title.textContent = nextq.question

  let description
  if (nextq.description) {
    description = document.createElement('div')
    description.classList.add('Triage__qa__description')
    description.textContent = nextq.description
  }

  const answerList = document.createElement('ul')
  answerList.classList.add('Triage__qa__answers')

  const answers = nextq.answers || []

  answers.forEach((a) => {
    const item = document.createElement('li')
    item.classList.add('Triage__qa__answers__answer')
    const button = document.createElement('button')
    item.dataset.triageNextq = JSON.stringify(a.nextQuestion || null)
    item.dataset.triageAction = JSON.stringify(a.action || null)
    item.dataset.action = 'answer'
    item.addEventListener('click', () => {
      onAnswerClick(item, componentId, contentfulName, mainElement)
    })
    item.dataset.triageQid = String(nextq.id)
    button?.addEventListener('click', () => {
      /* eslint-disable camelcase */
      const additionalProps = { question: nextq.question, answer: a.text }
      const componentData = {
        component_category: 'platinum-component',
        component_id: componentId,
        component_name: 'Triage',
        contentful_name: contentfulName,
        contentful_variant: 'triage-module',
      }
      trackComponent('Dynamic Component Event', 'CTAButtonClicked', componentData, {
        ...additionalProps,
      })
    })

    button.classList.add(
      'animated',
      'button_atom',
      'button_atom__secondary',
      'button_atom__mdTriage',
    )
    button.textContent = a.text
    item.appendChild(button)

    answerList.appendChild(item)
  })

  section.appendChild(title)
  description && section.appendChild(description)
  section.appendChild(answerList)
  mainElement.append(section)
}

const addActionLinks = (
  action: Action,
  componentId: string,
  contentfulName: string,
  mainElement: HTMLElement,
) => {
  const section = document.createElement('section')
  section.classList.add('Triage__action')

  const title = document.createElement('h3')
  title.classList.add('Triage__qa__ctaText')
  title.textContent = action.text
  section.appendChild(title)

  if (action.description) {
    const description = document.createElement('div')
    description.classList.add('Triage__qa__description')
    description.textContent = action.description
    section.appendChild(description)
  }

  const links = action.links ?? []

  if (links.length > 0) {
    const linkSection = document.createElement('section')
    linkSection.classList.add('Triage__action__links')
    links.forEach((l) => {
      const ctaLink = document.createElement('a')
      ctaLink.classList.add('Triage__action__links__link')
      ctaLink.href = l.href
      ctaLink.target = '_blank'
      ctaLink?.addEventListener('click', () => {
        /* eslint-disable camelcase */
        const additionalProps = { href: l.href, text: l.text }
        const componentData = {
          component_category: 'platinum-component',
          component_id: componentId,
          component_name: 'Triage',
          contentful_name: contentfulName,
          contentful_variant: 'triage-module',
        }
        trackComponent('Dynamic Component Event', 'CTAButtonClicked', componentData, {
          ...additionalProps,
        })
      })

      const ctaButton = document.createElement('button')
      ctaButton.classList.add('button_atom', 'button_atom__primary', 'button_atom__mdTriage')
      ctaButton.textContent = l.text

      ctaLink.appendChild(ctaButton)
      linkSection.appendChild(ctaLink)
    })
    section.appendChild(linkSection)
  }

  mainElement.append(section)
}

const unpickAnswers = (listItem: Node) => {
  const section = listItem?.parentElement

  if (section && section.hasChildNodes()) {
    const children = section.childNodes
    for (const node of children) {
      if (!node.isEqualNode(listItem)) {
        node.classList.add('unselected')
      }
    }
  }
}

const resetQuestions = (qid: number, triage: HTMLElement) => {
  const questions = triage.querySelectorAll("[data-triage='question']") as NodeListOf<HTMLElement>
  const clicked = Array.from(questions).find((q) => parseInt(q.dataset.triageIdValue) === qid)

  const answers = clicked.querySelectorAll('.Triage__qa__answers__answer')
  for (const answer of answers) {
    answer.classList.remove('unselected')
  }

  while (clicked.nextSibling) {
    clicked.parentElement.removeChild(clicked.nextSibling)
  }
}

const onAnswerClick = (answer, componentId, contentfulName, triage) => {
  const qid = parseInt(answer.getAttribute('data-triage-qid'))
  const action = JSON.parse(answer.getAttribute('data-triage-action')!)
  const nextq = JSON.parse(answer.getAttribute('data-triage-nextq')!)
  resetQuestions(qid, triage)
  unpickAnswers(answer)
  if (action) {
    addActionLinks(action, componentId, contentfulName, triage)
  } else if (nextq) {
    addQuestion(nextq, componentId, contentfulName, triage)
  }
}

triages.forEach((triage) => {
  const componentId = triage.getAttribute('data-triage-component-id')
  const contentfulName = triage.getAttribute('data-triage-contentful-name')
  const question = triage.querySelector("[data-triage='question']") as HTMLElement

  const existingButtons = question.querySelectorAll('.Triage__qa__answers__answer > button')
  existingButtons.forEach((b) => b.classList.add('animated'))

  const answers = triage.querySelectorAll("[data-action='answer']")

  answers?.forEach((answer) => {
    answer.addEventListener('click', () => {
      onAnswerClick(answer, componentId, contentfulName, triage)
    })
  })
})
