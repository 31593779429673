import { ConfigProps } from './set-config'

interface NewValueParams {
  path: string
  version?: string | number | null
  id?: string | null
  brand?: string | null
}

class ConfigSettings {
  public _apiPath: string

  public _clientVersion: string | number

  public _clientId: string

  public _brand: string

  constructor() {
    this._apiPath = '/consent'
    this._clientVersion = '1'
    this._clientId = 'rvuconsent'
    this._brand = 'money'
  }

  get apiPath() {
    return this._apiPath
  }

  get clientVersion() {
    return this._clientVersion
  }

  get clientId() {
    return this._clientId
  }

  get brand() {
    return this._brand
  }

  set apiPath(path: string) {
    this._apiPath = path
  }

  set clientVersion(version: string | number) {
    this._clientVersion = version
  }

  set clientId(id: string) {
    this._clientId = id
  }

  set brand(brand: string) {
    this._brand = brand
  }

  /**
   * setNewConfigValues
   */
  public setNewConfigValues(params: NewValueParams) {
    const { path, version = null, id = null, brand = null } = params

    if (path) this._apiPath = path
    if (version) this._clientVersion = version
    if (id) this._clientId = id
    if (brand) this._brand = brand

    return this.returnConfigObject()
  }

  /**
   * returnConfigObject
   */
  public returnConfigObject(): ConfigProps {
    return {
      apiPath: this._apiPath,
      clientVersion: this._clientVersion,
      clientId: this._clientId,
      brand: this._brand,
    }
  }
}

export default new ConfigSettings()
