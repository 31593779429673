const accordions = document.querySelectorAll('[data-spark-accordion]')

accordions.forEach((accordion) => {
  accordion.addEventListener('click', (element) => {
    if (accordion.dataset.sparkHydrated === 'true') {
      return
    }

    let target = element.target

    if (element.target.parentNode?.dataset?.sparkAccordionButton) {
      target = element.target.parentNode
    }

    const { dataset } = target

    if (dataset.sparkAccordionButton) {
      const open = dataset.sparkAccordionOpen === 'true'
      const content = target.nextSibling

      // Money specific
      const icon = target.querySelector('[data-ev-accordion]')

      // Uswitch specific
      const openIcon = target.querySelector('[data-ev-accordion-open-icon')
      const closeIcon = target.querySelector('[data-ev-accordion-close-icon')

      target.setAttribute('data-spark-accordion-open', !open)

      if (open) {
        content.style.height = '0px'
        content.style.overflow = 'hidden'
        content.childNodes[0].style.display = 'none'
        content.childNodes[0].childNodes[0].style.opacity = 0
        content.childNodes[0].childNodes[0].style.visibility = 'hidden'
        if (icon) {
          icon.style.transform = 'rotate(0.5turn)'
        }

        if (openIcon && closeIcon) {
          openIcon.style.display = 'block'
          closeIcon.style.display = 'none'
        }
      } else {
        content.style.height = 'auto'
        content.style.overflow = 'visible'
        content.childNodes[0].style.display = 'block'
        content.childNodes[0].childNodes[0].style.opacity = 1
        content.childNodes[0].childNodes[0].style.visibility = 'visible'
        if (icon) {
          icon.style.transform = 'none'
        }

        if (openIcon && closeIcon) {
          openIcon.style.display = 'none'
          closeIcon.style.display = 'block'
        }
      }
    }
  })
})
