/* eslint-disable camelcase */
declare global {
  interface Window {
    nerdalytics: any
  }
}

const isDev = window.__APP_ENV__ === 'development'

function track(eventGrouping: string, eventData: object) {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.log('[TRACKING] track: ', [eventGrouping, eventData])

    return Promise.resolve()
  } else {
    return new Promise((resolve) => {
      window.nerdalytics.track(eventGrouping, eventData, resolve)
    })
  }
}

interface AdditionalTrackingProps {
  event_name: string
  event_data?: string
}

interface ComponentData {
  component_category: string
  component_id: string
  component_name: string
  contentful_name: string
  contentful_variant: string
}

export function trackComponent(
  eventGrouping: string,
  eventName: string,
  componentData: ComponentData,
  eventData: object,
) {
  const additionalTrackProps: AdditionalTrackingProps = { ...componentData, event_name: eventName }

  if (eventData) {
    for (const key in eventData) {
      if (typeof eventData[key] === 'object') {
        // eslint-disable-next-line no-console
        console.warn('Please do not nest objects in eventData as it is difficult to parse')
      }
    }
  }

  additionalTrackProps.event_data = JSON.stringify(eventData)

  return track(eventGrouping, { ...additionalTrackProps })
}
