// @ts-strict-ignore
/* eslint-disable camelcase */
import { Collection } from './'
import { formatThousands } from '../../../utils/formatter'

interface Delivery {
  delivery_charge_percent_numeric: number
  delivery_charge_pounds_numeric: number
  maximum_amount_pounds_numeric: number
  minimum_amount_pounds_numeric: number
  tier_name: string
}

interface RateData {
  currency_iso_code: string
  currency_name: string
  standard_rate_tiers: StandardRateTier[]
}

interface StandardRateTier {
  tier_exchange_rate_numeric: number
  tier_maximum_amount_pounds_numeric: number
  tier_minimum_amount_pounds_numeric: number
  tier_name: string
}

export const getBestRate = (
  gbpAmount: number,
  otherAmount: number,
  currency: string,
  otherCurrency: string,
  collection: Collection,
  data: any,
) => {
  const rates = data
    .map((d) => getRate(gbpAmount, otherAmount, currency, otherCurrency, collection, d))
    .filter((r) => r != null)
  const filteredRates = rates.filter(
    (r) =>
      (collection === 'collection' && !!r.collection) ||
      (collection === 'delivery' && !!r.delivery),
  )
  const noMatchingRate = filteredRates.every((r) => r.error)
  if (noMatchingRate) {
    return filteredRates.sort((a, b) => b.maxOrder - a.maxOrder)[0]
  }

  return filteredRates.sort((a, b) => b.amountReceived - a.amountReceived)[0]
}

const getRate = (
  gbpAmount: number,
  otherAmount: number,
  currency: string,
  otherCurrency: string,
  collection: Collection,
  data: any,
) => {
  const rateData: RateData | undefined = data._source.definition.standard_rates.find(
    (d: RateData) => d.currency_iso_code === otherCurrency,
  )

  if (rateData) {
    let pounds: number
    const lowestRate = rateData.standard_rate_tiers[0]
    const highestRate = rateData.standard_rate_tiers[rateData.standard_rate_tiers.length - 1]
    if (currency === 'GBP') {
      pounds = gbpAmount
    } else {
      pounds = otherAmount / lowestRate.tier_exchange_rate_numeric
    }

    let err

    if (pounds < lowestRate.tier_minimum_amount_pounds_numeric) {
      err = {
        title: `The minimum order amount is 拢${formatThousands(
          data._source.definition.minimum_online_order_pounds_numeric,
        )}`,
        subtitle: 'Please enter a higher amount.',
      }
    }

    if (pounds > highestRate.tier_maximum_amount_pounds_numeric) {
      err = {
        title: `You've exceeded the maximum order amount of 拢${formatThousands(
          data._source.definition.maximum_online_order_pounds_numeric,
        )}`,
        subtitle: 'Please enter a lower amount.',
      }
    }

    if (
      collection === 'collection' &&
      data._source.definition.order_online_for_in_store_collection === false
    ) {
      err = {
        title: `Unfortunately, collection is not available for this order`,
        subtitle: 'Please amend your order.',
      }
    }

    if (
      collection === 'delivery' &&
      data._source.definition.order_online_for_home_delivery === false
    ) {
      err = {
        title: `Unfortunately, delivery is not available for this order`,
        subtitle: 'Please amend your order.',
      }
    }

    if (
      collection === 'delivery' &&
      data._source.definition.minimum_online_order_for_delivery_pounds_numeric != null &&
      pounds < data._source.definition.minimum_online_order_for_delivery_pounds_numeric
    ) {
      err = {
        title: `Are you sure?`,
        subtitle:
          "The amount you'll receive is less than the delivery fee for this order. Enter a higher amount, or choose collection instead.",
      }
    }

    if (err) {
      return {
        rate: lowestRate.tier_exchange_rate_numeric,
        collection: data._source.definition.order_online_for_in_store_collection,
        delivery: data._source.definition.order_online_for_home_delivery,
        deliveryRate: 0,
        logo: data._source.definition.provider.logo.money,
        name: data._source.definition.display_name,
        amountReceived: currency === 'GBP' ? 0 : -99999999,
        error: err,
        maxOrder: highestRate.tier_maximum_amount_pounds_numeric,
      }
    }

    const eligibleRates: any[] = rateData.standard_rate_tiers.filter(
      (r) =>
        pounds <= r.tier_maximum_amount_pounds_numeric &&
        pounds >= r.tier_minimum_amount_pounds_numeric,
    )
    if (!eligibleRates) {
      return null
    }
    const bestRate = eligibleRates.reduce((acc, d) => {
      const rate = d.tier_exchange_rate_numeric
      if (rate > acc) {
        const deliveryFee =
          collection === 'delivery'
            ? data._source.definition.delivery_charges?.find(
                (delivery: Delivery) =>
                  pounds <= delivery.maximum_amount_pounds_numeric &&
                  pounds >= delivery.minimum_amount_pounds_numeric,
              )?.delivery_charge_pounds_numeric ?? 0
            : 0

        const amountReceived =
          currency === 'GBP'
            ? (pounds - deliveryFee) * rate
            : 0 - (otherAmount / rate + deliveryFee) // we are doing 0 - val to be able to sort for best amount
        acc = {
          rate,
          collection: data._source.definition.order_online_for_in_store_collection,
          delivery: data._source.definition.order_online_for_home_delivery,
          deliveryRate: deliveryFee,
          logo: data._source.definition.provider.logo.money,
          name: data._source.definition.display_name,
          amountReceived,
          maxOrder: highestRate.tier_maximum_amount_pounds_numeric,
        }
        return acc
      }
      return acc
    }, 0)

    return bestRate
  }

  return null
}
