import getWindow from './get-window'

// on the server side, we don't care for this working correctly
// so when the window.Chart isn't defined, we just return a mock
// object with an empty `register` function
const getChart = () => {
  return (
    getWindow().Chart || {
      Chart: { register: () => {} },
    }
  )
}

export default getChart
