import { formatThousands } from '../utils/formatter'
import { getBestRate } from '../components/platinum-modules/travel-money-calculator/calculation'
import {
  flagMappings,
  currencyPairOptions,
  gbpCurrency,
  currencyMappings,
} from '../components/platinum-modules/travel-money-calculator/mappings'

const travelMoneyData = document.querySelector('[data-travel-money-script-data]')?.textContent

document.querySelectorAll('[data-all-travel-money-content]').forEach((selector) => {
  const collectionRadioButtons = selector.querySelectorAll(
    '[data-travel-collection-selector-radio]',
  )
  const travelInput = selector.querySelector('[data-travel-input-selector-content]')
  const currencyInput = selector.querySelector('[data-input-prefix-selector-content]')
  const travelSelect = selector.querySelectorAll('[data-travel-select-currency-content]')
  const swapCurrency = selector.querySelector('[data-travel-swap-currency-button]')
  const bestRateLogo = selector.querySelector('[data-travel-money-logo-data]')?.children[0]
  const conversionText = selector.querySelector('[data-travel-money-conversion-text-data]')
  const loadingSpinner = selector.querySelector('[data-travel-loading-spinner-content]')
  const errorText = selector.querySelector('[data-travel-money-error-data]')
  const noResultText = selector.querySelector('[data-travel-money-no-result-data]')
  const bestRateResult = selector.querySelectorAll('[data-travel-money-best-rate-data]')
  const span_receivedLeft = selector.querySelector('[data-travel-money-received-left-data]')
  const span_receivedRight = selector.querySelector('[data-travel-money-received-right-data]')
  const amountError = selector.querySelector('[data-travel-money-amount-error-content]')
  const showOtherResults = selector.querySelectorAll('[data-show-other-results-content]')
  const showResults = selector.querySelector('[data-travel-money-show-results]')

  interface BestRate {
    rate: number
    collection: boolean
    delivery: boolean
    deliveryRate: string | number
    logo: string
    name: string
    amountReceived: number
    error?: string
  }

  let amount: number = 750
  let currencyToUse: string = 'GBP'
  let toCurrency: string
  let swapped: boolean = false
  let collectionType = 'delivery'
  let parsedData: string = ''
  let bestRate: BestRate

  const updateResultUrl = () => {
    showResults!.children[0].href = `/travel-money/results?gbpAmount=${amount}&currencyToUse=${currencyToUse}&currencyPair=${
      swapped ? currencyToUse : toCurrency
    }&otherAmount=${amount}&collectionType=${collectionType}`
  }

  const updateResult = () => {
    updateResultUrl()
    bestRateLogo!.attributes[0].value = bestRate.logo
    bestRateLogo!.style.width = '150px'
    conversionText!.children[0].innerText = ` 1 ${currencyToUse} = ${
      swapped ? (1 / bestRate.rate).toFixed(3) : bestRate.rate
    } ${toCurrency}`
    conversionText!.children[1].innerText = ` 1 ${toCurrency} = ${
      swapped ? bestRate.rate : (1 / bestRate.rate).toFixed(3)
    } ${currencyToUse}`
    if (span_receivedLeft) {
      span_receivedLeft.innerHTML = `${formatThousands(amount)} ${currencyToUse} =`
    }
    if (span_receivedRight) {
      const amountReceived =
        bestRate.amountReceived &&
        (currencyToUse === 'GBP'
          ? bestRate.amountReceived.toFixed(2)
          : (0 - bestRate.amountReceived).toFixed(2))
      span_receivedRight.innerHTML = `${formatThousands(amountReceived)} ${toCurrency}`
    }
    bestRateResult.forEach((resultDisplay) => (resultDisplay.style.display = 'flex'))
  }

  const updateReceivedAmount = (newAmount: number, showLoading: boolean) => {
    bestRate = getBestRate(
      newAmount,
      newAmount,
      currencyToUse,
      swapped ? currencyToUse : toCurrency,
      collectionType,
      parsedData,
    )
    if (bestRate.error || travelInput!.error || amount === 0) {
      bestRateResult.forEach((resultDisplay) => (resultDisplay.style.display = 'none'))
      if (travelInput!.error || amount === 0) {
        errorText!.children[0].textContent = ''
        errorText!.children[1].textContent = ''
        noResultText!.textContent = 'There are no results matching your search, please try again.'
      } else {
        noResultText!.textContent = ''
        errorText!.children[0].innerHTML = bestRate.error.title
        errorText!.children[1].innerHTML = bestRate.error.subtitle
      }
      updateResultUrl()
    } else {
      noResultText!.textContent = ''
      errorText!.children[0].textContent = ''
      errorText!.children[1].textContent = ''
      if (showLoading) {
        bestRateResult.forEach((resultDisplay) => (resultDisplay.style.display = 'none'))
        loadingSpinner!.style.display = 'flex'
        setTimeout(() => {
          loadingSpinner!.style.display = 'none'
          updateResult()
        }, 100)
      } else {
        loadingSpinner!.style.display = 'none'
        updateResult()
      }
    }
  }

  const initiate = () => {
    toCurrency = travelSelect[1].value
    parsedData = JSON.parse(travelMoneyData)
    showOtherResults.forEach((otherResult) => (otherResult.style.display = 'none'))
    updateReceivedAmount(amount, false)
  }
  initiate()

  Array.from(collectionRadioButtons).forEach((radioButton) => {
    radioButton.addEventListener('click', () => {
      Array.from(collectionRadioButtons).forEach((rb, i) => {
        const index = parseInt(radioButton.dataset.travelCollectionSelectorRadio)
        if (i === index) {
          rb.parentElement.classList.add('radio-checked')
          index === 0 ? (collectionType = 'delivery') : (collectionType = 'collection')
          updateReceivedAmount(amount, true)
        } else {
          rb.parentElement.classList.remove('radio-checked')
        }
      })
    })
  })

  travelInput?.addEventListener('input', () => {
    const valueString = travelInput.value
    let newAmount = formatThousands(valueString.replace(/[^0-9]/g, '')).replace(/^0+/, '')
    if (isNaN(parseInt(newAmount))) {
      newAmount = '0'
    }
    travelInput.value = newAmount
    const intAmount = parseInt(newAmount.replace(/,/g, ''))
    amount = intAmount
    if (intAmount > 0 && (currencyToUse === 'GBP' ? intAmount <= 7500 : intAmount <= 999999999)) {
      travelInput.error = false
      amountError!.innerHTML = ''
      updateReceivedAmount(intAmount, true)
    } else {
      travelInput.error = true
      amountError!.innerHTML = 'Min order amount 拢1, max 拢7,500'
      updateReceivedAmount(intAmount, false)
    }
  })

  travelSelect?.forEach((select) => {
    select.addEventListener('change', (e) => {
      if (e.target.id === 'from-currency') {
        currencyToUse = select.value
        select.style.backgroundImage = `url(/fs-core-assets/icons/money/${flagMappings[currencyToUse]}.svg?v=13#${flagMappings[currencyToUse]}), url(/fs-core-assets/icons/money/chevron-down.svg?v=13)`
        currencyInput!.innerHTML =
          currencyMappings.find((c) => c.code === currencyToUse)?.symbol ?? ''
      } else {
        toCurrency = select.value
        select.style.backgroundImage = `url(/fs-core-assets/icons/money/${flagMappings[toCurrency]}.svg?v=13#${flagMappings[toCurrency]}), url(/fs-core-assets/icons/money/chevron-down.svg?v=13)`
      }
      updateReceivedAmount(amount, true)
    })
  })

  swapCurrency?.addEventListener('click', () => {
    while (travelSelect[0].options.length) {
      travelSelect[0].remove(0)
    }
    while (travelSelect[1].options.length) {
      travelSelect[1].remove(0)
    }
    if (!swapped) {
      swapped = true
      for (let i = 0; i < currencyPairOptions.length; i++) {
        const newOption = document.createElement('option')
        newOption.value = currencyPairOptions[i].value
        newOption.innerHTML = currencyPairOptions[i].text
        travelSelect[0].appendChild(newOption)
      }
      for (let i = 0; i < gbpCurrency.length; i++) {
        const newOption = document.createElement('option')
        newOption.value = gbpCurrency[i].value
        newOption.innerHTML = gbpCurrency[i].text
        newOption.style.backgroundImage = `url(/fs-core-assets/icons/money/${
          flagMappings[gbpCurrency[i].value]
        }.svg?v=13#${
          flagMappings[gbpCurrency[i].value]
        }), url(/fs-core-assets/icons/money/chevron-down.svg?v=13)`
        travelSelect[1].appendChild(newOption)
      }
    } else {
      swapped = false
      for (let i = 0; i < gbpCurrency.length; i++) {
        const newOption = document.createElement('option')
        newOption.value = gbpCurrency[i].value
        newOption.innerHTML = gbpCurrency[i].text
        travelSelect[0].appendChild(newOption)
      }
      for (let i = 0; i < currencyPairOptions.length; i++) {
        const newOption = document.createElement('option')
        newOption.value = currencyPairOptions[i].value
        newOption.innerHTML = currencyPairOptions[i].text
        travelSelect[1].appendChild(newOption)
      }
    }
    amount = 1000
    travelInput.value = formatThousands(amount)
    toCurrency = [currencyToUse, (currencyToUse = toCurrency)][0]
    travelSelect[0].style.backgroundImage = `url(/fs-core-assets/icons/money/${flagMappings[currencyToUse]}.svg?v=13#${flagMappings[currencyToUse]}), url(/fs-core-assets/icons/money/chevron-down.svg?v=13)`
    travelSelect[1].style.backgroundImage = `url(/fs-core-assets/icons/money/${flagMappings[toCurrency]}.svg?v=13#${flagMappings[toCurrency]}), url(/fs-core-assets/icons/money/chevron-down.svg?v=13)`
    travelSelect[0].value = currencyToUse
    travelSelect[1].value = toCurrency
    currencyInput!.innerHTML = currencyMappings.find((c) => c.code === currencyToUse)?.symbol ?? ''
    updateReceivedAmount(amount, true)
  })
})
