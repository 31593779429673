// @ts-strict-ignore
import { ChartConfiguration, ChartDataset } from '@types/chart.js'

export interface Configuration {
  indexAxis: 'x' | 'y'
  displayXAxisTick: boolean
  displayYAxisTick: boolean
  deviceType: 'desktop' | 'mobile'
  labelFormatter?: (context: any) => string
  xAxisTickFormatter?: (value: string | number, index: number, ticks: any) => string | number
  yAxisTickFormatter?: (value: string | number, index: number, ticks: any) => string | number
}

export const getChartConfig = (
  type: 'line' | 'bar' | 'doughnut',
  labels: string[][],
  dataSetObjects: ChartDataset[],
  configuration: Configuration,
): ChartConfiguration => {
  const {
    indexAxis,
    displayXAxisTick,
    displayYAxisTick,
    labelFormatter,
    xAxisTickFormatter,
    yAxisTickFormatter,
    deviceType,
  } = configuration
  return {
    type: type,

    data: {
      labels: labels,
      datasets: dataSetObjects,
    },
    options: {
      animation: false,
      aspectRatio: 2,
      maintainAspectRatio: false,
      indexAxis: indexAxis,
      scales: {
        x: {
          display: type !== 'doughnut',
          grid: {
            offset: false,
            display: type !== 'doughnut',
          },
          ticks: {
            display: displayXAxisTick,
            maxRotation: 30,
            autoSkip: type === 'line',
            // precision: 0,
            ...(xAxisTickFormatter && { callback: xAxisTickFormatter }),
            font: {
              size: 12,
              family:
                "-apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Segoe WP', Ubuntu, sans-serif",
            },
          },
        },
        y: {
          display: type !== 'doughnut',
          ticks: {
            display: displayYAxisTick,
            precision: 0,
            ...(yAxisTickFormatter && { callback: yAxisTickFormatter }),
            font: {
              size: 14,
              family:
                "-apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Segoe WP', Ubuntu, sans-serif",
            },
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: type === 'doughnut' && deviceType === 'desktop' ? 'right' : 'bottom',
          align: type === 'doughnut' ? 'start' : 'center',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            textAlign: 'left',
            padding: 20,
          },
          ...(type === 'doughnut' && {
            generateLabels: (chart) => {
              const datasets = chart.data.datasets
              return datasets[0].data.map((data, i) => ({
                text: ` ${chart.data.labels[i]} ${data}`,
                fillStyle: datasets[0].backgroundColor[i],
              }))
            },
          }),
        },
        tooltip: {
          ...(labelFormatter && {
            callbacks: {
              label: labelFormatter,
            },
          }),
        },
      },
      elements: {
        line: {
          borderJoinStyle: 'round',
          borderCapStyle: 'round',
        },
      },
    },
  }
}
