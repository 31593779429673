setTimeout(() => {
  const stickyBannerData = document.getElementById('stickyBannerElement')
  const brand = window.__BRAND__

  if (stickyBannerData) {
    const text = stickyBannerData.dataset.stickyBannerText
    const link = stickyBannerData.dataset.stickyBannerLink
    const ctaText = stickyBannerData.dataset.stickyBannerCtatext
    const appBody = document.getElementById('app')

    const stickybanner = document.createElement('div')

    stickybanner.classList.add('StickyBanner', 'StickyBanner__sticky')
    stickybanner.innerText = text

    const CTAWrapper = document.createElement('div')
    CTAWrapper.classList.add('StickyBanner__ctaWrapper')
    const CTA = document.createElement('a')
    CTA.href = link
    CTA.classList.add('button_link')
    const CTAdiv = document.createElement('div')
    CTAdiv.innerText = ctaText
    CTAdiv.classList.add(
      'button_atom_link',
      'button_atom',
      'button_atom__secondary',
      'button_atom__xs',
    )
    CTA.appendChild(CTAdiv)
    CTAWrapper.appendChild(CTA)
    stickybanner.appendChild(CTAWrapper)

    const useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElement.setAttribute('href', `/fs-core-assets/icons/${brand}/cross.svg?v=14#cross`)

    const svgClose = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svgClose.classList.add('DynamicIcon__13', 'DynamicIcon__desktop__24')
    svgClose.setAttribute('role', 'presentation')
    svgClose.appendChild(useElement)

    const svgWrapper = document.createElement('div')
    svgWrapper.classList.add('StickyBanner__close')
    svgWrapper.appendChild(svgClose)

    stickybanner.appendChild(svgWrapper)

    appBody?.prepend(stickybanner)
    stickybanner.style.visibility = 'visible'
    stickybanner.style.opacity = '1'

    svgWrapper.addEventListener('click', () => {
      stickybanner.style.display = 'none'
    })
  }
}, 1000)
