import { calculateLoanRepayment } from './calculator-helper'
import { formatThousands } from '../utils/formatter'
import yieldToMain from '../utils/yield-to-main'
import { debounce } from '../utils/debounce'

const loanRepaymentCalculator = document.querySelector('[data-loan-repayment-calculator]')

const fields = {
  amount: 7500,
  duration: 5,
  apr: 3.7,
}

const updateCells = () => {
  const monthlyPaymentCell = loanRepaymentCalculator.querySelector(
    '[data-loan-repayment-calculator-monthly-payments]',
  )
  const costOfCreditCell = loanRepaymentCalculator.querySelector(
    '[data-loan-repayment-calculator-cost-of-credit]',
  )
  const aprCell = loanRepaymentCalculator.querySelector('[data-loan-repayment-calculator-apr]')
  const totalCell = loanRepaymentCalculator.querySelector('[data-loan-repayment-calculator-total]')

  const { monthlyPayment, total, totalInterest } = calculateLoanRepayment(fields)

  monthlyPaymentCell.innerText = `拢${monthlyPayment}`
  costOfCreditCell.innerText = `拢${totalInterest}`
  aprCell.innerText = `${fields.apr}%`
  totalCell.innerText = `拢${total}`
}

const handleInputBlur = (e) => {
  if (e.target.value === '') {
    e.target.value = '0'
  }
}
const handleDecimalBlur = (e) => {
  const value = e.target.value
  if (value.endsWith('.')) {
    e.target.value = value.replace(/\.$/, '')
  }
}
const handleDuplicateZerosChange = (e) => {
  if (parseInt(e.target.value) === 0) {
    e.target.value = '0'
  }
}
const handleAmountInputChange = async (e) => {
  const digitsValue = e.target.value.replace(/\D/g, '')
  const n = parseInt(digitsValue)
  e.target.value = digitsValue === '' ? '' : formatThousands(n)
  await yieldToMain()
  if (n !== fields.amount) {
    fields.amount = digitsValue === '' ? 0 : n
    updateCells()
  }
}
const handleDurationInputChange = async (e) => {
  const value = e.target.value.replace(/\D/g, '')
  const n = parseInt(value)
  e.target.value = value
  await yieldToMain()
  if (n !== fields.duration) {
    fields.duration = value === '' ? 0 : n
    updateCells()
  }
}
const handleAprInputChange = async (e) => {
  const value = e.target.value.replace(/[^\d.]/g, '')
  const singleDotValue = value.split('.').slice(0, 2).join('.')
  const n = parseFloat(singleDotValue)
  e.target.value = singleDotValue
  await yieldToMain()
  if (n !== fields.apr) {
    fields.apr = value === '' ? 0 : n
    updateCells()
  }
}

const run = () => {
  if (!loanRepaymentCalculator) {
    return
  }

  const amountInput = loanRepaymentCalculator.querySelector('input[name="loan-amount"]')
  const durationInput = loanRepaymentCalculator.querySelector('input[name="loan-duration"]')
  const aprInput = loanRepaymentCalculator.querySelector('input[name="loan-apr"]')

  const inputs = [amountInput, durationInput, aprInput]
  inputs.forEach((input) => {
    input.addEventListener('blur', debounce(handleInputBlur, 200))
    input.addEventListener('keyup', debounce(handleDuplicateZerosChange, 200))
  })

  aprInput.addEventListener('blur', debounce(handleDecimalBlur, 200))
  amountInput.addEventListener('keyup', debounce(handleAmountInputChange, 200))
  durationInput.addEventListener('keyup', debounce(handleDurationInputChange, 200))
  aprInput.addEventListener('keyup', debounce(handleAprInputChange, 200))
}

run()
