// @ts-strict-ignore
const round = (x: number) => Math.round(x * 100) / 100

const wholeNumber = Intl.NumberFormat('en-UK', { minimumFractionDigits: 0 })
const twoDPNumber = Intl.NumberFormat('en-UK', { minimumFractionDigits: 2 })

const formatNumber = (x: number) =>
  x % 1 === 0 ? wholeNumber.format(round(x)) : twoDPNumber.format(round(x))

export const calculateLoanRepayment = ({
  amount,
  apr,
  duration,
}: {
  amount: number
  apr: number
  duration: number
}) => {
  const principal = amount
  const interest = apr / 100 / 12
  const payments = duration * 12

  const x = Math.pow(1 + interest, payments)
  const monthly = (principal * x * interest) / (x - 1)

  if (
    !isNaN(monthly) &&
    monthly !== Number.POSITIVE_INFINITY &&
    monthly !== Number.NEGATIVE_INFINITY
  ) {
    const monthlyPayment = formatNumber(monthly)
    const total = formatNumber(monthly * payments)
    const totalInterest = formatNumber(monthly * payments - principal)

    return {
      monthlyPayment,
      total,
      totalInterest,
    }
  } else {
    return {
      monthlyPayment: 0,
      total: 0,
      totalInterest: 0,
    }
  }
}
