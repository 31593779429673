// @ts-strict-ignore
import { Configuration, getChartConfig } from './chart-config'
import getChart from '../utils/get-chart'

const {
  Chart: ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} = getChart()

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const colors = {
  money: ['#DB4D75', '#924A8B', '#F4793B'],
  uswitch: ['#9EDDEF', '#FFB3EE', '#C8EC7F'],
}
const ThreePlusColors = {
  money: [
    '#FEC340',
    '#FBAA3E',
    '#F7923D',
    '#F4793B',
    '#EC6A4E',
    '#E35C62',
    '#DB4D75',
    '#C34C7C',
    '#AA4B84',
    '#924A8B',
    '#734877',
    '#534762',
    '#34454E',
  ],
  uswitch: [
    '#F7F146',
    '#FFD555',
    '#FFAA55',
    '#FFB3EE',
    '#FF80DA',
    '#FFA7A2',
    '#FF7585',
    '#C1C0FF',
    '#AC96DE',
    '#9EDDEF',
    '#84A6FF',
    '#C8EC7F',
    '#C8EC7F',
  ],
}

const labelFormatter =
  (labelType: 'currency' | 'percentage', axis: 'x' | 'y', graphType: 'bar' | 'doughnut' | 'line') =>
  (context: any) => {
    let label = graphType === 'doughnut' ? context.label[0] || '' : context.dataset.label || ''
    const value = graphType === 'doughnut' ? context.parsed : context.parsed[axis]

    if (label) {
      label += ': '
    }
    if (value !== null && labelType === 'currency') {
      label += new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
      }).format(value)

      return label
    }

    if (value !== null && labelType === 'percentage') {
      label = label + `${value} %`

      return label
    }
    if (value !== null) {
      label = label + value

      return label
    }

    return label
  }

const axisFormatter = (labelType: 'currency' | 'percentage') => (value: string | number) => {
  return labelType === 'currency' ? '拢' + value : labelType === 'percentage' ? value + '%' : value
}

const charts = document.querySelectorAll('[data-chart]')

charts?.forEach((e) => {
  const config = JSON.parse(e.getAttribute('data-chart-config')!)
  const dataset = JSON.parse(e.getAttribute('data-chart-dataset')!)
  const labels = JSON.parse(e.getAttribute('data-chart-labels')!)

  const type: 'line' | 'bar' | 'doughnut' = config.type
  const label = config.label

  const deviceWidth = window.innerWidth

  const deviceType = deviceWidth > 768 ? 'desktop' : 'mobile'

  const swapIndexAxis = type === 'bar' && deviceType === 'desktop'

  const dataSetObjects = dataset?.map((set, i) => {
    return {
      label: set[0],
      data: set.slice(1).map((item: number | string) => parseFloat(item)),
      backgroundColor:
        type === 'doughnut' ? ThreePlusColors[window.__BRAND__] : colors[window.__BRAND__][i],
      borderColor:
        type === 'doughnut' ? ThreePlusColors[window.__BRAND__] : colors[window.__BRAND__][i],
      indexAxis: swapIndexAxis ? 'y' : 'x',
      pointRadius: 4,
      maxBarThickness: 100,
    }
  })

  const configuration: Configuration = {
    indexAxis: swapIndexAxis ? 'y' : 'x',
    displayXAxisTick: true,
    displayYAxisTick: true,
    labelFormatter: labelFormatter(label, swapIndexAxis ? 'x' : 'y', type),
    xAxisTickFormatter: swapIndexAxis ? axisFormatter(label) : undefined,
    yAxisTickFormatter: swapIndexAxis ? undefined : axisFormatter(label),
    deviceType,
  }
  const ctx = e.querySelector('[ data-chart-canvas]')?.getContext('2d')

  const chartConfig = getChartConfig(
    type,
    labels.map((l: string) => l.split('*')), // allows formatting of axis labels
    dataSetObjects,
    configuration,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const chart = new ChartJS(ctx, chartConfig)
})
