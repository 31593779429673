// @ts-ignore
const brand = window.__BRAND__
const nav = document.querySelector('[data-sticky-top-nav]') as HTMLElement
const isSticky = nav?.getAttribute('data-sticky-top-nav-is-sticky')

const navScroll = () => {
  const windowWidth = window.innerWidth
  const width = window.innerWidth - (brand === 'money' ? 1186 : 1168)

  const navHeight = nav.getBoundingClientRect().height
  if (windowWidth < 769) {
    if (window.scrollY > 0) {
      nav?.classList.add('PpcNav__sticky')
      document.getElementById('app')!.style.paddingTop = `${navHeight}px`

      nav.style.paddingLeft = `${width > 0 ? width / 2 : 16}px`
      nav.style.paddingRight = `${width > 0 ? width / 2 : 16}px`
      if (brand === 'money') {
        nav.style.width = '-webkit-fill-available'
      }
    } else {
      nav?.classList.remove('PpcNav__sticky')
      nav.style.paddingLeft = '0'
      nav.style.paddingRight = '0'
      document.getElementById('app')!.style.paddingTop = `0`
    }
  }
}

if (brand === 'confused') {
  document.getElementById('app')!.style.marginTop = `0`
}
if (nav && isSticky === 'true') {
  window.addEventListener('scroll', navScroll)
} else {
  window.removeEventListener('scroll', navScroll)
}
