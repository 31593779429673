function calculate(calculator) {
  const url = calculator.getAttribute('data-calculator-url')
  const urlParams = Array.from(calculator.querySelectorAll('[data-calculator-value]'))
    .map((calculatorValue) => {
      const format = calculatorValue.getAttribute('data-format')
      const value =
        format === 'thousands' ? calculatorValue.value.replace(/,/g, '') : calculatorValue.value
      return `${calculatorValue.name}=${value}`
    })
    .join('&')

  window.location.href = `${url}?${urlParams}`
}

function run() {
  const calculator = document.querySelector('[data-calculator-url]')

  if (!calculator) {
    return
  }

  const calculatorValues = Array.from(calculator.querySelectorAll('[data-calculator-value]'))

  calculatorValues.forEach((calculatorValue) => {
    const format = calculatorValue.getAttribute('data-format')
    if (format === 'thousands') {
      calculatorValue.addEventListener('keyup', () => {
        const tempNumber = calculatorValue.value.replace(/,/g, '')
        const commaSeparated = tempNumber.split(/(?=(?:\d{3})+$)/).join(',')
        calculatorValue.value = commaSeparated
      })
    }
  })

  const calculateButton = calculator.querySelector('[data-button-action="calculate"]')
  if (!calculateButton) {
    return
  }

  calculateButton.addEventListener('click', (event) => {
    event.preventDefault()
    calculate(calculator)
  })
}

run()
