import ConfigSettings from './config'

export interface ConfigProps {
  apiPath: string
  clientVersion?: string | number
  clientId?: string
  brand?: string
}

export const setConfig = (props: ConfigProps): ConfigProps => {
  const {
    apiPath = '/consent',
    clientVersion = 1,
    clientId = 'rvuconsent',
    brand = 'money',
  } = props

  return ConfigSettings.setNewConfigValues({
    path: apiPath,
    version: clientVersion,
    id: clientId,
    brand,
  })
}
